import React from 'react';
import { Deck, Heading, SlideLayout, FlexBox } from 'spectacle';
import { Avatar, Headline, ProgressBar, BaseStyles, Badge, ButtonGroup, BodyLarge, spacing, Row, Col, Grid } from '@sumup/circuit-ui';
// _app.tsx for Next.js or App.js for CRA
import { ThemeProvider, css } from '@emotion/react';
import { light } from '@sumup/design-tokens';
import './App.css'
import { VictoryLine, VictoryChart, VictoryTheme, VictoryLabel } from 'victory';

const theme = {
	colors: {
		primary: 'black',
		secondary: 'black'
	},
	fonts: {
		header: '"Helvetica Neue", Helvetica, Arial, sans-serif'
	},
	fontSizes: {
		h1: '72px',
		h2: '64px'
	},
	backdropStyle: { backgroundColor: 'white' },
	'background-color': 'white',
	size: {
		width: '100%',
		height: '80vh'
	}
};

const timerArgs = {
	label: 'A time-based progressbar',
	hideLabel: true,
	duration: 3000,
	paused: false,
	loop: true,
	max: null,
};

const progressBarStyles = css`
	width: 90%;
	min-width: 500px;
	margin-top: 20px;
`;

const template = ({ slideNumber, numberOfSlides }) => (
	<FlexBox
		justifyContent="space-between"
		position="absolute"
		bottom={0}
		width={1}
	>
	</FlexBox>
);

const titleProps = {
	margin: '30px'
}

const largeBodyStyle = {
	fontSize: '3rem',
	lineHeight: 1.1,
	margin: '20px 0'

}

const codeDesignTokensSpacings = `
export const spacings: Spacings = {
  bit: '4px',
  byte: '8px',
  kilo: '12px',
  mega: '16px',
  giga: '24px',
  tera: '32px',
  peta: '40px',
  exa: '48px',
  zetta: '56px',
};
`

const codeDesignTokensColours = `
const blues = {
  b100: '#F0F6FF',
  b200: '#DAEAFF',
  b300: '#AFD0FE',
  b400: '#7FB5FF',
  b500: '#3063E9',
  b700: '#234BC3',
  b900: '#1A368E',
};
`

const codeCodemod = `
const transform: Transform = (file, api) => {
  const j = api.jscodeshift;
  const root = j(file.source);

  const oldComponentName = 'LoadingButton';
  const newComponentName = 'Button';

  const imports = findImportsByPath(j, root, '@sumup/circuit-ui');
  const componentImport = imports.find((i) => i.name === oldComponentName);

  root
    .find(j.Identifier)
    .filter((nodePath) => nodePath.node.name === oldComponentName)
    .replaceWith(j.identifier(newComponentName));

  return root.toSource();
};
`

function App() {
	return (
		<ThemeProvider theme={light}>
			<BaseStyles />
			<div className='presentation-container'>
				<Deck theme={theme} css="width: 100%;" template={template}>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Heading><h1>10 Things Not to do<br /> With a Design System</h1></Heading>
					</SlideLayout.Full>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Heading>WTF is Circuit UI?</Heading>
						{/* <Headline><a target="sumup_circuit_ui" href="https://circuit.sumup.com" >Circuit UI</a> is our Web Component Library / Design System</Headline> */}
						<Headline>Avatars:</Headline>
						<Avatar
							alt="A jonathan"
							size="yotta"
							src="https://jonathanmh.com/assets/jonathan.webp"
							variant="identity"
						/>
						<Headline>Buttons:</Headline>
						<ButtonGroup
							actions={{
								primary: {
									children: 'Look again',
									onClick: function noRefCheck() { }
								},
								secondary: {
									children: 'Go elsewhere',
									href: function noRefCheck() { }
								}
							}}
						/>
						<Headline>Progress Bars:</Headline>
						<ProgressBar {...timerArgs} css={progressBarStyles} size="mega" />
						<Headline>and much more</Headline>
						<video controls autoplay muted loop playsInline style={{ maxWidth: '50vw', margin: '0 auto' }} src="/cui-components.mov"></video>
					</SlideLayout.Full>
					<SlideLayout.HorizontalImage titleProps={titleProps} title="" src="/product/dashboard.png" backgroundColor={theme['background-color']}></SlideLayout.HorizontalImage>
					<SlideLayout.VerticalImage src="/circuit-ui-repo.png" backgroundColor={theme['background-color']} listItems={['Frontend Engineers', 'Mobile Engineers', 'Product Designers', 'Motion Designers', 'UX Writers']}>
					</SlideLayout.VerticalImage>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Headline>How did it start?</Headline>
						<video controls autoplay muted playsInline style={{ maxWidth: '80vw', margin: '0 auto' }} src="/repo-history.webm" type="video/webm"></video>
					</SlideLayout.Full>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Heading><h1>Don't support multiple JS frameworks</h1></Heading>
						<Grid>
							<Row>
								<Col span="4">
									<Avatar
										style={{ margin: '0 auto' }}
										alt="react logo"
										size="yotta"
										src="/react-logo512.png"
										variant="identity"
									/>
								</Col>
								<Col span="4">
									<Avatar
										style={{ margin: '0 auto' }}
										alt="backbone logo"
										size="yotta"
										src="/backbone-logo.png"
										variant="identity"
									/>

								</Col>
								<Col span="4">
									<Avatar
										style={{ margin: '0 auto' }}
										alt="vue logo"
										size="yotta"
										src="/vue-logo.png"
										variant="identity"
									/>
								</Col>
							</Row>
						</Grid>
					</SlideLayout.Full>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Headline>Multiple Framework Support is Hard</Headline>
						<VictoryChart theme={VictoryTheme.material} >
							<VictoryLabel text="complexity" textAnchor="middle" y={20} />
							<VictoryLine
								style={{
									data: { stroke: "black", strokeWidth: 5 },
									labels: { background: 'white' }
								}}
								labels={[
									'buttons',
									'typography',
									'forms',
									'static css export',
									'another frontend framework'
								]}
								data={[
									{ x: 1, y: 1 },
									{ x: 2, y: 2 },
									{ x: 3, y: 3 },
									{ x: 4, y: 8 },
									{ x: 5, y: 100 }
								]}
							/>
						</VictoryChart>
					</SlideLayout.Full>
					<SlideLayout.Code language="js" children={codeDesignTokensSpacings} title="Design Tokens!" backgroundColor={theme['background-color']}></SlideLayout.Code>
					<SlideLayout.Code language="js" children={codeDesignTokensColours} title="More Design Tokens!" backgroundColor={theme['background-color']}></SlideLayout.Code>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Heading><h1>Don't Harm Accessibility</h1></Heading>
						<Grid style={largeBodyStyle}>
							<Row>
								<Col span="6">Status:<Badge style={{ width: '30px', height: '30px' }} variant="danger"></Badge></Col>
								<Col span="6">Status:<Badge style={{ width: '30px', height: '30px' }} variant="neutral"></Badge></Col>
							</Row>
							<Row>
								<Col span="6">Status:<Badge style={{ width: '30px', height: '30px' }} variant="success"></Badge></Col>
								<Col span="6">Status:<Badge style={{ width: '30px', height: '30px' }} variant="neutral"></Badge></Col>
							</Row>
						</Grid>
						<BodyLarge css={largeBodyStyle}>Don't rely on colour alone!</BodyLarge>
						<Grid style={largeBodyStyle}>
							<Row>
								<Col span="6"><Badge css={spacing('giga')} style={{ width: '10vw', fontSize: '3rem', minHeight: '4rem', paddingTop: '1.1rem' }} variant="danger">Failed</Badge></Col>
								<Col span="6"><Badge css={spacing('giga')} style={{ width: '10vw', fontSize: '3rem', minHeight: '4rem', paddingTop: '1.1rem' }} variant="neutral">Failed</Badge></Col>
							</Row>
							<Row>
								<Col span="6"><Badge css={spacing('giga')} style={{ width: '10vw', fontSize: '3rem', minHeight: '4rem', paddingTop: '1.1rem' }} variant="success">Paid </Badge></Col>
								<Col span="6"><Badge css={spacing('giga')} style={{ width: '10vw', fontSize: '3rem', minHeight: '4rem', paddingTop: '1.1rem' }} variant="neutral">Paid </Badge></Col>
							</Row>
						</Grid>
					</SlideLayout.Full>
					<SlideLayout.VerticalImage src="/mouse.jpg" backgroundColor={theme['background-color']} listItems={['throw your mouse away', '(for a day)', 'try to use your product']}>
					</SlideLayout.VerticalImage>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Headline>TAB, SPACE, ARROWS, ENTER</Headline>
						<video controls autoplay muted loop playsInline style={{ maxWidth: '100%', maxHeight: '70vh', margin: '0 auto' }} src="/product/mouseless-invoice.mp4"></video>
					</SlideLayout.Full>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Heading><h1>Don't Dictate other Libraries</h1></Heading>
					</SlideLayout.Full>
					<SlideLayout.HorizontalImage src="/spaghetti.jpg" backgroundColor={theme['background-color']}></SlideLayout.HorizontalImage>
					<SlideLayout.VerticalImage src="/dishes.jpg" backgroundColor={theme['background-color']} listItems={['react-final-form', 'react-hooks-form', 'zustand', 'context', 'next cool thing']}></SlideLayout.VerticalImage>
					<SlideLayout.HorizontalImage titleProps={titleProps} title="Don't Abandon Docs" src="/docs-textarea.png" backgroundColor={theme['background-color']}></SlideLayout.HorizontalImage>
					<SlideLayout.HorizontalImage titleProps={titleProps} title="Don't Hide Examples" src="/docs-no-code.png" backgroundColor={theme['background-color']}></SlideLayout.HorizontalImage>
					<SlideLayout.HorizontalImage titleProps={titleProps} title="Make it easy for outsiders" src="/docs-like-this.png" backgroundColor={theme['background-color']}></SlideLayout.HorizontalImage>
					<SlideLayout.HorizontalImage titleProps={titleProps} title="Don't Theme Bootstrap with it 🙃" src="/pug.png" backgroundColor={theme['background-color']}>
					</SlideLayout.HorizontalImage>
					<SlideLayout.VerticalImage src="/pug.jpg" backgroundColor={theme['background-color']} listItems={['wears a jacket', 'still a pug', 'formerly known as jade']}>
					</SlideLayout.VerticalImage>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Heading>Don't be Hasty!</Heading>
						<BodyLarge style={largeBodyStyle}> &gt; Default component margins have been deprecated since v2, and the use of the `noMargin` prop was encouraged to <strong>ensure that UIs don't rely on the default margin</strong>.</BodyLarge>
					</SlideLayout.Full>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Heading>Don't be a Hero</Heading>
						<BodyLarge style={largeBodyStyle}> &gt; Some of the changes in this guide can be automated with <strong>codemods, small scripts that modify your app's source code automatically.</strong> Changes that can be codemodded are marked with a robot emoji (🤖) and the name of the transform (e.g. _button-variant-enum_)</BodyLarge>
					</SlideLayout.Full>
					<SlideLayout.Code language="js" children={codeCodemod} title="Codemods are awesome" backgroundColor={theme['background-color']}></SlideLayout.Code>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Heading>Try out codemods!</Heading>
						<BodyLarge style={largeBodyStyle}>The library we use is <a href="https://github.com/facebook/jscodeshift">jscodeshift</a>. (It has a convenient react plugin too)</BodyLarge>
					</SlideLayout.Full>
					<SlideLayout.HorizontalImage titleProps={titleProps} title="Don't Forget UX" src="/figma.png" backgroundColor={theme['background-color']}></SlideLayout.HorizontalImage>
					<SlideLayout.HorizontalImage titleProps={titleProps} title="" src="/product/stuck-on-4.png" backgroundColor={theme['background-color']}></SlideLayout.HorizontalImage>
					<SlideLayout.HorizontalImage titleProps={titleProps} title="(we have a lot of forms)" src="/product/customer-single.png" backgroundColor={theme['background-color']}></SlideLayout.HorizontalImage>
					<SlideLayout.HorizontalImage titleProps={titleProps} title="(seriously a lot)" src="/product/product.png" backgroundColor={theme['background-color']}></SlideLayout.HorizontalImage>
					<SlideLayout.VerticalImage title="Don't be shy" src="/shy-hamster.jpg" backgroundColor={theme['background-color']}>
					</SlideLayout.VerticalImage>
					<SlideLayout.VerticalImage title="" src="/welcome.jpg" backgroundColor={theme['background-color']} listItems={['host office hours', 'reach out (!)', 'ask others to work with you']}></SlideLayout.VerticalImage>
					<SlideLayout.Full backgroundColor={theme['background-color']}>
						<Heading>Thank you!</Heading>
						<BodyLarge style={largeBodyStyle}>get the slides at: <a href="https://slides.jonathanmh.com">slides.jonathanmh.com</a></BodyLarge>
						<BodyLarge style={largeBodyStyle}>Check out:</BodyLarge>
						<BodyLarge style={largeBodyStyle}><a href="https://circuit.sumup.com">Circuit UI</a></BodyLarge>
						<BodyLarge style={largeBodyStyle}><a href="https://gource.io/">Gource</a> for awesome repo animations</BodyLarge>
						<BodyLarge style={largeBodyStyle}><a href="https://formidable.com/open-source/spectacle/">Spectacle</a> if you need to render a react component library in your slides</BodyLarge>
					</SlideLayout.Full>
				</Deck>
			</div>
		</ThemeProvider >
	);
}

export default App;
